<template>
<div>
    <v-container mt-5 mb-5></v-container>
    <v-container fluid class="pa-0 backgroundFooterPrograma">
        <v-container mt-4></v-container>
        <p class="text-center letraBlanca">
            Edad de contratación de 18 a 65 años. A los 76 años del Titular, se cancela este servicio.<br>
            Conoce a detalle cada uno de tus servicios y consulta Términos y Condiciones de tus Asistencias y Seguro
        </p>
        <v-layout>
            <v-flex xs12 md10 class="mx-auto pt-3 pb-3 ">
                <v-layout wrap>
                    <v-flex xs4 md2 class="offset-md-1 align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/promass.png" />
                    </v-flex>
                    <v-flex xs4 md2 class="align-self-center pa-2">
                        <v-img max-width="80" class="mx-auto" src="@/assets/img/footer/productos/club.png" />
                    </v-flex>
                    <v-flex xs4 md2 class="align-self-center pa-2">
                        <v-img max-width="90" class="mx-auto" src="@/assets/img/footer/productos/hb.png" />
                    </v-flex>
                    <v-flex xs6 md2 class="align-self-center pa-2">
                        <v-img max-width="120" class="mx-auto" src="@/assets/img/footer/productos/panam.png" />
                    </v-flex>
                    <v-flex xs6 md2 class="align-self-center pa-2">
                        <v-img max-width="120" class="mx-auto" src="@/assets/img/footer/productos/chedraui.png" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</div>
</template>

<script>
export default {
    name: "FooterMiSeguro"
}
</script>
