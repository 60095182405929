<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/miseguro/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-10></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/LogoDespensa.png')" />
    </v-container>
    <v-container mt-1 mb-1></v-container>
    <v-container>
        <p class="azul font-24 text-center font-weight-bold">
            Cómo usar el servicio de Mi Seguro:
        </p>
    </v-container>
    <v-container>
        <v-layout>
            <v-flex xs12 md10 class="mx-auto">
                <ol>
                    <li class="pa-3 negro text-justify">
                        Llama al <span class="naranja font-weight-bold">55 5481 6021</span>, marca la opción de Protección Despensa y después de escuchar el menú, selecciona la opción <span class="naranja font-weight-bold">opción 5</span> de Mi Seguro para que seas atendido por la aseguradora, quien te indicará los pasos a seguir.
                    </li>
                    <li class="pa-3 negro text-justify">Envía los formatos y la documentación solicitada tanto en original como
                        digitalizada.
                    </li>
                    <li class="pa-3 negro text-justify">Una vez que entregues el expediente completo y sea un siniestro procedente,
                        se realizará el reembolso vía transferencia electrónica en un plazo máximo de
                        10 días hábiles.
                        <br><br>
                        <p class="naranja font-weight-bold text-center">Los horarios de atención de las Aseguradoras es de Lunes a Viernes de 8:00 a 17:30 horas.</p>
                    </li>
                </ol>
            </v-flex>
        </v-layout>
    </v-container>
    <v-container>
        <p class="azul font-24 text-center font-weight-bold">
            Documentos a presentar para el trámite<br>
            de Mi Seguro:
        </p>
    </v-container>
    <v-container>
        <v-layout wrap>
            <v-flex xs12 md6 class="pa-4">
                <ul>
                    <li class="pa-2 text-justify negro">Consentimiento / Certificado Individual en original
                        firmado por el asegurado(a).
                    </li>
                    <li class="pa-2 text-justify negro">Acta de defunción del asegurado(a)<br>
                        <span class="naranja">(original o copia certificada).</span>
                    </li>
                    <li class="pa-2 text-justify negro">Acta de nacimiento del Titular asegurado(a)<br>
                        <span class="naranja">(original o copia certificada).</span>
                    </li>
                    <li class="pa-2 text-justify negro">Acta de matrimonio o constancia de concubinato
                        en caso de que el beneficiario(a) sea el cónyuge<br>
                        <span class="naranja">(en original o copia certificada).</span>
                    </li>
                    <li class="pa-2 text-justify negro">Acta de nacimiento de cada uno de los
                        beneficiarios(as) manifestados en el consentimiento<br>
                        <span class="naranja">(original o copia certificada).</span>
                    </li>
                    <li class="pa-2 text-justify negro">Copia por ambos lados de la identificación oficial
                        del Titular asegurado(a) <span class="naranja">(credencial del INE,
                            pasaporte o cartilla)</span>, visible, legible y actualizada,
                        en donde se aprecien con toda claridad sus
                        rasgos faciales.
                    </li>
                </ul>
            </v-flex>
            <v-flex xs12 md6 class="pa-4">
                <ul>
                    <li class="pa-2 text-justify negro">Copia por ambos lados de la Identificación oficial
                        de cada uno del o los beneficiarios(as)
                        manifestados en el consentimiento <span class="naranja">(credencial del
                            INE, pasaporte o cartilla)</span> que sean visibles, legibles
                        y actualizadas, en donde se aprecien con toda
                        claridad sus rasgos faciales de cada uno de ellos.
                    </li>
                    <li class="pa-2 text-justify negro">Comprobante de domicilio de cada uno de los
                        beneficiarios, con vigencia actual no mayor a tres
                        meses.
                    </li>
                    <li class="pa-2 text-justify negro">Actuaciones del Ministerio Público en caso de muerte
                        accidental <span class="naranja">(original o copia certificada)</span>, anexando
                        inspección ministerial del lugar de los hechos,
                        identificación del cadáver, declaraciones testimoniales,
                        necropsia, exámenes químico-toxicológicos
                        y dictamen final.
                    </li>
                </ul>
            </v-flex>
        </v-layout>
    </v-container>
    <v-container>
        <p class="naranja font-24 text-center font-weight-bold">
            Consulta las Condiciones Generales en la página<br>
            <a class="naranja" target="_blanck" href="https://www.palig.com/despensa-protegida-voluntaria">https://www.palig.com/despensa-protegida-voluntaria</a>
        </p>
        <p class="azul font-17 text-center">
            El trámite de la reclamación iniciará con los documentos digitales.<br>
            Para el pago, deberás presentar los documentos originales para su cotejo.
        </p>
    </v-container>
    <Footer />
</div>
</template>

<script>
import Footer from "@/components/FooterMiSeguro.vue";

export default {
    name: "MiSeguro",
    components:{
        Footer
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerMiSeguroMovil';
                case 'sm':
                    return 'BannerMiSeguroMovil';
                case 'md':
                    return 'BannerMiSeguro';
                case 'lg':
                    return 'BannerMiSeguro';
                case 'xl':
                    return 'BannerMiSeguro';
            }

        }
    }
}
</script>

<style>
ul {
    list-style: none;
}

ul li::before {
    content: "\2022";
    color: #f47627;
    font-weight: bold;
    padding-right: .4em;
    width: 1em;
    margin-left: -1em;
}

ol {
    list-style: none;
    counter-reset: li
}

ol li {
    counter-increment: li
}

ol li::before {
    color: #f47627;
    content: counter(li) ".";
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
</style>
